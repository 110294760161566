import { Injectable } from '@angular/core';
import country_list from '../../../../app/shared/data/country_list.json';
import usa_states_list from '../../../../app/shared/data/usa_states_list.json';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { environment } from '@env/environment';
@Injectable({
  providedIn: 'root'
})
export class BatutaService {

  constructor(
    private http: HttpClient ) { }

  getCountries() {
    return country_list as [string];
  }

  async getStatesFor(countryCode: string) {
    // if (countryCode === 'US - United States') {
    //   return usa_states_list as [string];
    // } else {
    //   return [];
    // }

    var url = `https://www.universal-tutorial.com/api/states/${countryCode}`;
    // const url = `${service}?fn=${functionName}&DomainId=${params.domainId}`;
    const token = await this.getToken();
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Accept': 'application/json'
    });
    var currentInformation = await this.http.get<any>(url, {
      headers
    }).pipe(take(1)).toPromise();
    currentInformation = currentInformation.map(data => {return {text: data.state_name, value: data.state_name}})
    console.log(currentInformation);
    return currentInformation;
  }

  async getToken(){
    const headers = new HttpHeaders({
      Accept: `application/json`,
      'api-token': environment.tokenUniversal.token,
      'user-email': "alan@trueomni.com"
    });
    const token = await this.http.get<any>("https://www.universal-tutorial.com/api/getaccesstoken", {
      headers,
    }).pipe(take(1)).toPromise();
    console.log(token)
    return token.auth_token
  }
}
