import { Component, OnInit, OnDestroy } from '@angular/core';

import { CMSDataControllerService } from '@app/core/data/controller/cmsdata-controller.service';
import { CMSEvent } from '@app/core/data/CMSEvent';
import { environment } from '@env/environment';
import moment from 'moment';
import { SubCategory } from '@app/core/data/SubCategory';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
declare function require(name:string);

import { Storage } from '@ionic/storage';
@Component({
  selector: 'app-change-region-row',
  templateUrl: require('template/' + environment.template + '/html/change-region-row.component.html'),
  styles: [require('template/' + environment.template + '/styles/change-region-row.component.scss')]
})
export class ChangeRegionRowComponent implements OnInit {
  currentRegion: any;
  constructor(
    private router: Router,
    public storage: Storage,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private dataController: CMSDataControllerService,
    ) {
  }

  async ngOnInit() {
    this.route.queryParams.subscribe(async (params) => {
      this.currentRegion = await this.storage.get("activeRegion")
      if (!this.currentRegion) {
        this.currentRegion = {
          BackgroundImage: "https://omniexcdn.blob.core.windows.net/omnicms/1033/PWA/Regions/centralcoast.png",
          ButtonImage: "https://omniexcdn.blob.core.windows.net/omnicms/1033/Regions/central coast.png",
          DateModified: "2024-12-14T01:55:07.267",
          DomainID: 0,
          Icon: "assets/icons/pin_map.svg",
          IconHeight: 60,
          IconWidth: 45,
          PageData: "",
          PageID: 9251,
          PageName: "CENTRAL COAST",
          ParentPageID: 9250,
          SortOrder: 1,
          active: true,
          bDisplayContent: false,
          pageRedirect: "",
          pageType: 2,
          restricted: false,
          rssLink: "100001",
          zipCode: 92660,
          zIndex: 1000}
      await this.storage.set("activeRegion", this.currentRegion)
    }
    })
  }


}
