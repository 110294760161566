import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { NgModule } from '@angular/core';

const routes: Routes = [
  { path: 'me-tnt', loadChildren: './codebase/me-tnt/me-tnt.module#MeTntPageModule' },
  { path: 'category-toolbar', loadChildren: './codebase/category/category-toolbar/category-toolbar.module#CategoryToolbarPageModule' },
  { path: 'passport', loadChildren: './passport/passport.module#PassportPageModule' },
  { path: 'i-frame-modal', loadChildren: './i-frame-modal/i-frame-modal.module#IFrameModalPageModule' },
  { path: 'gallery-modal', loadChildren: './gallery-modal/gallery-modal.module#GalleryModalPageModule' },
  { path: 'venues', loadChildren: './passport/venues/venues.module#VenuesPageModule' },
  { path: 'itinerariesv2', loadChildren: './codebase/itinerariesv2/itinerariesv2.module#Itinerariesv2PageModule' },  { path: 'category-rows', loadChildren: './codebase/category-rows/category-rows.module#CategoryRowsPageModule' }



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
