import { Component, OnInit } from '@angular/core';

import { CMSDataControllerService } from '@app/core/data/controller/cmsdata-controller.service';
import { CMSEvent } from '@app/core/data/CMSEvent';
import { environment } from '@env/environment';
import moment from 'moment';
import { SubCategory } from '@app/core/data/SubCategory';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
declare function require(name:string);

import { Storage } from '@ionic/storage';
import { LoadingService } from '../services/loading.service';
import { DeepLinkHelper } from '@app/core/data/utils/DeepLinkHelper';
@Component({
  selector: 'app-featured-events-row',
  templateUrl: require('template/' + environment.template + '/html/featured-events-row.component.html'),
  styles: [require('template/' + environment.template + '/styles/featured-events-row.component.scss')]
})
export class FeaturedEventsRowComponent implements OnInit {

  public environment = environment;
  events!: CMSEvent[];
  listings!: SubCategory[];
  filteredListings!: Map<string,boolean>;
  visibleListings!: SubCategory[];
  categoriesAllowed = environment.eventsCategorysAllowed;
  filterEventsByCategories = environment.filterEventsByCategories;
  visibleEvents!: CMSEvent[];

  language = this.translate.currentLang;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public storage: Storage,
    private loading: LoadingService,
    public translate: TranslateService,
    private dataController: CMSDataControllerService,
    ) {
      storage.get('language').then(lang => {
      console.log(lang)
      translate.setDefaultLang(lang || 'en')
    })
  }

  async ngOnInit() {
    this.route.queryParams.subscribe(async (params) => {
      const activeRegion = await this.storage.get("activeRegion")
      if (!this.environment.site.featuredEvents) return;
      await this.loading.presentLoading();
      let map = new Map();
      this.events = await this.storage.get("visibleEvents");
      if (this.events){
        this.events = this.events.slice(0,10);
        this.loading.dismiss();
      }else{
        const location = await this.storage.get("lastLocation")
        let promises = [];
          if (environment.hasEventsDates){
            promises.push(
              this.dataController
                .getEventsDates2({
                  domainId: environment.cms.domainId,
                  dataSyndication: true,
                  latitude: location.latitude,
                  longitude: location.longitude,
                  daysCount: 28,
                  firstDayOfWeek: 0
                })
                .toPromise()
            );
            promises.push(
              this.dataController
                .getEvents2({
                  domainId: environment.cms.domainId,
                  regionId: activeRegion ? activeRegion.rssLink : null,
                  dataSyndication: true,
                })
                .toPromise()
            );
          }else{
              promises.push(
                this.dataController
                  .getEvents2({
                    domainId: environment.cms.domainId,
                    regionId: activeRegion ? activeRegion.rssLink : null,
                    dataSyndication: true,
                  })
                  .toPromise()
              );
          }
          Promise.all(promises).then(
            async (list: any) => {
              if (!environment.hasEventsDates){
                if (!list[0] || !list[0].length){
                  this.events = [];
                  this.visibleEvents = [];
                  this.loading.dismiss();
                  return;
                }
                this.events = list[0].sort((a,b) => a.StartDate - b.StartDate)
              }else{
                if (!list[1] || !list[1].length){
                  this.events = [];
                  this.visibleEvents = [];
                  this.loading.dismiss();
                  return;
                }
                let eventDates = this.filterAndSortEventsByCurrentDate(list[0].Result);
                let eventIdsValid = this.getValidEventsWithNextOccurrence(eventDates, list[1]).map(event => event.EventID);

                this.events = list[0].filter(obj => eventIdsValid.includes(obj.EventId)  // Comparar con booleano normalizado
              ).sort(
            (a, b) =>
              new Date(a.StartDate).getTime() - new Date(b.StartDate).getTime()
          );
              }


              // // Inicializar otras variables
              if (this.filterEventsByCategories) {
                this.visibleEvents = this.events.filter(
                  (event) =>
                    this.categoriesAllowed.includes(+event.SubCategoryID || event.SubCategoryId)
                ).sort(
            (a, b) =>
              new Date(a.StartDate).getTime() - new Date(b.StartDate).getTime()
          );;
              } else {
                this.visibleEvents = this.events.sort(
            (a, b) =>
              new Date(a.StartDate).getTime() - new Date(b.StartDate).getTime()
          );
              }


              this.loading.dismiss();
              this.events = this.visibleEvents.slice(0, 10);
              await this.storage.set("visibleEvents", this.visibleEvents);
            },
            (err) => {

              this.loading.dismiss();
            }
          );
        }
      })
  }

  filterAndSortEventsByCurrentDate(events: CMSEvent[]): CMSEvent[] {
    const today = moment().startOf("day");

    // Filter events with dates equal to or after the current day
    const filteredEvents = events.filter((event) => {
      const eventDate = moment.tz(event.StartDate, "UTC").startOf("day");
      return eventDate.isSameOrAfter(today, "day");
    });

    return filteredEvents;
  }

  getValidEventsWithNextOccurrence(
    firstResponse: any[],
    secondData: CMSEvent[]
  ): CMSEvent[] {
    const validEvents: CMSEvent[] = [];

    // Iterate through the response of the first function
    for (const firstEvent of firstResponse) {
      // Find the corresponding event in the second data
      const matchingEvent = secondData.find(
        (event) => event.EventID === firstEvent.EventId || event.EventId === firstEvent.EventId
      );


      // Validate if a corresponding event is found
      if (matchingEvent) {
        // Check if the event has an EndDate
        if (matchingEvent.EndDate) {
          // Validate if the EventDate is within the range StartDate - EndDate
          if (
            moment(firstEvent.StartDate).isBetween(
              matchingEvent.StartDate,
              matchingEvent.EndDate,
              "day",
              "[]"
            )
          ) {
            validEvents.push({
              ...matchingEvent,
              StartDate: firstEvent.StartDate,
            });
          }
        } else {
          // The event does not have an EndDate, find the next occurrence from the current day
          const nextOccurrence = moment(matchingEvent.StartDate)
            .startOf("day")
            .add(1, "week"); // You can adjust the logic as needed

          // Validate if the StartDate is within the range StartDate - Next occurrence
          if (
            moment(firstEvent.StartDate).isBetween(
              matchingEvent.StartDate,
              nextOccurrence,
              "day",
              "[]"
            )
          ) {
            validEvents.push({
              ...matchingEvent,
              StartDate: nextOccurrence.toISOString(),
            });
          }
        }
      }
    }

    return validEvents;
  }

  format(date: string, format: string): string {
    return moment(date).format(format);
  }

  onClick(event){
    console.log(event);
    // this.router.navigate(['/tabs/category'], { queryParams: { t: event.SubCategoryName, subCategoryId: event.SubCategoryID } });
    if (event.Id)
      this.router.navigate([`tabs/events/${event.Id}`])
    else if (event.EventId)
      this.router.navigate([`tabs/events/${event.EventId}`])
  }

  inRange(rangeStart: Date, rangeEnd?: Date, eventStartDate?: string, eventEndDate?: string): boolean {
    if (!eventStartDate || !eventEndDate) {
      // an event it's considered candidate to check his range if has a non-null start & end dates
      return false;
    }
    if(new Date(eventStartDate).getTime() >= rangeStart.getTime()){
      return true
    }

    return false;
  }

}
