import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AgmCoreModule } from '@agm/core';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import { CategoriesListComponent } from '@app/codebase/category/categories-list/categories-list.component';
import { CommonModule } from '@angular/common';
import { CompleteYourProfileComponent } from '@app/shared/complete-your-profile/complete-your-profile.component';
import { EmptyStateComponent } from '@app/codebase/empty-state/empty-state.component';
import { FavoriteListingsHorizontalComponent } from './favorite-listings-horizontal/favorite-listings-horizontal.component';
import { FeaturedEventsRowComponent } from './featured-events-row/featured-events-row.component';
import { GenericAlertComponent } from './generic-alert/generic-alert.component';
import { AdAlertComponent } from './ad-alert/ad-alert.component';
import { IonicModule } from '@ionic/angular';
import { IonicRatingModule } from 'ionic4-rating';
import { ListingCellComponent } from './listing-cell/listing-cell.component';
import { ListingCellTntComponent } from './listing-cell-tnt/listing-cell-tnt.component';
import { ListingsMapComponent } from '@app/codebase/listings/listings-map/listings-map.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedComponent } from './shared.component';
import { UserSearchInboxHeaderComponent } from './user-search-inbox-header/user-search-inbox-header.component';
import { WebViewComponent } from './web-view/web-view.component';
import { environment } from '@env/environment';
import { MainTopBarV2Component } from './main-top-bar-v2/main-top-bar-v2.component';
import { LikedListingsComponent } from '@app/codebase/itineraries/liked-listings/liked-listings.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { createTranslateLoader } from '@app/app.module';
import { HttpClient } from '@angular/common/http';
import { VenuesPage } from '@app/passport/venues/venues.page';
import { BackButtonDirective } from '@app/directives/back-button.directive';
import { FavoriteListingsComponent } from '@app/codebase/itinerariesv2/favorite-listings/favorite-listings.component';
import { ChooseDateComponent } from '@app/codebase/itinerariesv2/choose-date/choose-date.component';
import { ItineraryDetailsComponent } from '@app/codebase/itineraries/itinerary-details/itinerary-details.component';
import { ItineraryDayCellComponent } from '@app/codebase/itineraries/itinerary-details/itinerary-day-cell/itinerary-day-cell.component';
import { StopCellComponent } from '@app/codebase/itineraries/itinerary-details/stop-cell/stop-cell.component';
import { CreateItineraryComponent } from '@app/codebase/itinerariesv2/create-itinerary/create-itinerary.component';
import { ChangeRegionRowComponent } from './change-region-row/change-region-row.component';
import { AdvertisementsComponent } from './advertisements/advertisements.component';
import { ChunkPipe } from '@app/chunk.pipe';
import { TabsHomeWidgetComponent } from './tabs-home-widget/tabs-home-widget.component';
import { RegionsTabComponent } from './tabs-home-widget/regions-tab/regions-tab.component';
import { TrailsTabComponent } from './tabs-home-widget/trails-tab/trails-tab.component';
import { DeleteProfileComponent } from '@app/codebase/me-tnt/settings/delete-profile/delete-profile.component';
import { ConfirmDeleteComponent } from '@app/codebase/me-tnt/settings/confirm-delete/confirm-delete.component';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { RegionChildrensComponent } from '@app/codebase/regions/region-childrens/region-childrens.component';

@NgModule({
  imports: [
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    RouterModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleMaps,
      libraries: ['geometry']
    }),
    AgmJsMarkerClustererModule,
    AgmSnazzyInfoWindowModule,
    IonicRatingModule,
  ],
  declarations: [
    BackButtonDirective,
    SharedComponent,
    UserSearchInboxHeaderComponent,
    MainTopBarV2Component,
    GenericAlertComponent,
    AdAlertComponent,
    ListingsMapComponent,
    ListingCellComponent,
    LikedListingsComponent,
    FavoriteListingsComponent,
    ChooseDateComponent,
    ItineraryDetailsComponent,
    ItineraryDayCellComponent,
    StopCellComponent,
    CreateItineraryComponent,
    ListingCellTntComponent,
    FeaturedEventsRowComponent,
    ChangeRegionRowComponent,
    RegionChildrensComponent,
    TabsHomeWidgetComponent,
    RegionsTabComponent,
    TrailsTabComponent,
    FavoriteListingsHorizontalComponent,
    WebViewComponent,
    AdvertisementsComponent,
    EmptyStateComponent,
    ChunkPipe,
    VenuesPage,
    CategoriesListComponent,
    CompleteYourProfileComponent,
    DeleteProfileComponent,
    ConfirmDeleteComponent
  ],
  exports: [
    SharedComponent,
    UserSearchInboxHeaderComponent,
    MainTopBarV2Component,
    GenericAlertComponent,
    AdAlertComponent,
    ListingsMapComponent,
    LikedListingsComponent,
    FavoriteListingsComponent,
    ChooseDateComponent,
    ItineraryDetailsComponent,
    ItineraryDayCellComponent,
    StopCellComponent,
    CreateItineraryComponent,
    ListingCellComponent,
    ListingCellTntComponent,
    FeaturedEventsRowComponent,
    ChangeRegionRowComponent,
    RegionChildrensComponent,
    TabsHomeWidgetComponent,
    RegionsTabComponent,
    TrailsTabComponent,
    FavoriteListingsHorizontalComponent,
    WebViewComponent,
    EmptyStateComponent,
    ChunkPipe,
    AdvertisementsComponent,
    VenuesPage,
    CategoriesListComponent,
    DeleteProfileComponent,
    ConfirmDeleteComponent
  ],
  entryComponents: [
    GenericAlertComponent,
    AdAlertComponent,
    VenuesPage,
    LikedListingsComponent,
    FavoriteListingsComponent,
    ChooseDateComponent,
    ItineraryDetailsComponent,
    ItineraryDayCellComponent,
    StopCellComponent,
    CreateItineraryComponent,
    CompleteYourProfileComponent,
    DeleteProfileComponent,
    ConfirmDeleteComponent
  ]
})
export class SharedComponentModule { }
