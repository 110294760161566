export const settings = {
  languages: [
    { label: "ENGLISH", value: 'en' },
    { label: 'SPANISH', value: 'es' },
    { label: 'FRENCH', value: 'fr' },
    { label: 'GERMAN', value: 'de' },
    { label: 'CHINESE', value: 'sc' },
    { label: 'VIETNAMESE', value: 'vi' }
  ],
  categoriesMapping: {
    30: "accommodations",
    40: "dining",
    47: "attractions",
    64: "shopping",
    99: "entertainment",
    118: "activities",
  },
site: {
  name: 'Visit Pensacola',
  address: '1300 1st St #313, Napa, CA 94559, United States',
  phone: '+17072515895',
  map: 'https://www.google.com/maps/place/Napa+Valley+Welcome+Center/@38.2982627,-122.2880484,15z/data=!4m5!3m4!1s0x0:0xbe5255fdb1c96d57!8m2!3d38.2982627!4d-122.2880484',
  latitude: 30.417575,
  longitude: -87.193379,
  website:'https://www.visitpensacola.com/',
  privacyLink:'https://www.visitpensacola.com/privacy-policy/',
  twitter:'https://twitter.com/VisitPensacola',
  facebook: 'https://www.facebook.com/visitpensacola',
  youtube:'',
  instagram:'https://www.instagram.com/visitpensacola/',
  pinterest:'https://www.pinterest.com.mx/visitpensacola/',
  splashImage: '../themes/Pensacola/assets/splashscreens/bg_img.png',
  homeEvents: false,
  featuredEvents: false,
  socialWallHashtag: 'Follow us @visitnapavalley'
},

organizations: [
  {
    logo: '',
    website: 'https://www.visitpensacola.com/',
    phone: '+17072515895',
    location: 'https://goo.gl/maps/fAEWXMGzotSfHYrf8',
    latitude: 30.4175833,
    longitude: -87.1933889,
    address: 'Pensacola Visitor Information Center<br />1401 E Gregory St, Pensacola, FL 32502',
  },
  {
    logo: '',
    website: 'https://www.visitpensacola.com/',
    phone: '+17072515895',
    location: 'https://goo.gl/maps/gTYwTBA39kpud4ib9',
    latitude: 30.2917753,
    longitude: -87.4695566,
    address: 'Perdido Key Visitor Information Center<br />15500 Perdido Key Dr. Perdido Key, FL 32507',
  }
],
eventsCategorysAllowed: [
  4, 30, 16, 7, 8, 9, 50
],
hasEventsData: true,
hasEventsDates: true,
filterEventsByCategories: true,
filterEventsByfeature: false,
filterListingsByfeature: false,
hasRegions: false,
showChangeLanguageLogin: true,
showGradient: false,
displaySocial: true,
homeOptions: {
  enabled: false,
  optionPageId: 9276,
  fullWithFirstItemMenu: false
},
debug: true

};
