import { AgmFitBounds, AgmMap } from '@agm/core';
import { AfterViewInit, Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Listing } from '@app/core/data/Listing';
import { environment } from '@env/environment';
import { Events, IonSlides } from '@ionic/angular';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Observable, fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';
import { Advertisement } from '@app/core/data/Advertisement';

declare function require(name: string);
@Component({
  selector: 'app-listings-map',
  templateUrl: require('template/' + environment.template + '/html/listings-map.component.html'),
  styles: [require('template/' + environment.template + '/styles/listings-map.component.scss')]
})

export class ListingsMapComponent implements OnInit {

  @Input() listings: Listing[] = [];
  @Input() isLoading: Boolean = true;
  @Input() advertisements: Advertisement[] = [];
  @ViewChildren('img') ionImages: QueryList<ElementRef>;

  filteredArray: Listing[] = [];
  latitude: number;
  longitude: number;
  userLocation!: { latitude: number, longitude: number };
  zoom: number = 11;
  selectedItem!: Listing;
  slideImages: string[] = [];
  windowH: number;
  map!: google.maps.Map;
  markers: google.maps.Marker[] = [];
  previousIndex: number = 0;
  currentIndex1: number = 0;
  private focusedMarker: google.maps.Marker | null = null;

  public environment = environment;

  @ViewChild(AgmMap) agmMap!: AgmMap;
  @ViewChild(AgmFitBounds) fitBounds!: AgmFitBounds;
  @ViewChild('slideWithNav2') slideWithNav2!: IonSlides;

  // slideOptsTwo = {
  //   initialSlide: 0,
  //   slidesPerView: 1.3,
  //   spaceBetween: 20
  // };

  slideOptsTwo = {
    initialSlide: 0,
    slidesPerView: 1.3,
    spaceBetween: 2,
    center: true,
    centeredSlides: true,
    centeredSlidesBounds: true
  };
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private geolocation: Geolocation,
    public events: Events,
    private mapsAPILoader: MapsAPILoader
  ) {
    this.latitude = environment.cms.main_coordinates.latitude;
    this.longitude = environment.cms.main_coordinates.longitude;

    this.events.subscribe('testevent', (data) => {
      if (data.listings) {
        this.listings = data.listings
        this.slideWithNav2.update();
        this.previousIndex = 0;
        this.filteredArray = this.listings;
        this.initializeMap();
        this.isLoading = false;
        if (this.filteredArray.length == 0) return;
        this.latitude = this.filteredArray[0].Latitude;
        this.longitude = this.filteredArray[0].Longitude;
      }
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.subscribeToEvents();
    })
  }

  private subscribeToEvents() {
    this.events.subscribe('testevent', (data) => {
      if (data.listings && data.listings.length) {
        this.listings = data.listings
        this.slideWithNav2.update();
        this.previousIndex = 0;
        this.filteredArray = this.listings;
        this.latitude = this.filteredArray[0].Latitude;
        this.longitude = this.filteredArray[0].Longitude;
        this.isLoading = false;
        this.initializeMap();
        this.listings.forEach(list => {
            switch (list.CategoryName.toLowerCase()) {
              case 'stay':
              case 'lodging':
                list.CategoryName = 'Places to Stay'
                break;
              case 'explore':
                list.CategoryName = 'Things to do'
                break;
              case 'dine':
                list.CategoryName = 'Restaurant and Dining'
                break;
              case 'shop':
                list.CategoryName = 'Shopping'
                break;
              default:
                break;
            }
        })
      }
    });
  }

  onOpenListingClick(item: Listing) {
    // this.showLoginPopup(item.ListingID)
    this.router.navigate(['/tabs/listings', item.ListingID]);
  }


  private initializeMap() {
    this.mapsAPILoader.load().then(() => {
      this.map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
        center: { lat: this.latitude, lng: this.longitude },
        zoom: 12,
        minZoom: 5,
        maxZoom: 20,
        disableDefaultUI: true,
        gestureHandling: 'greedy'
      });

      this.addMarkersInBatches(this.map, this.filteredArray);

      // Listen to map bounds changes using RxJS
      fromEvent(document.getElementById('map') as HTMLElement, 'bounds_changed').pipe(
        map(() => this.map.getBounds())
      ).subscribe((bounds) => {
        if (bounds) {
          this.onBoundsChange(bounds);
        }
      });
    });
  }

addMarkersInBatches(map: google.maps.Map, markersData: any[], batchSize: number = 20) {
  let allMarkers: google.maps.Marker[] = [];
  let currentIndex = 0;

  function processBatch() {
    const batch = markersData.slice(currentIndex, currentIndex + batchSize);
    const batchMarkers = batch.map((item, idx) => {
      const marker =  new google.maps.Marker({
        position: { lat: item.Latitude, lng: item.Longitude },
        map: map,
        icon: {
          url: item.Icon,
          size: new google.maps.Size(item.IconWidth || 22, item.IconHeight || 30),
          scaledSize: new google.maps.Size(item.IconWidth || 22, item.IconHeight || 30),
        },
        zIndex: item.zIndex || 1,
        optimized: true,
        clickable: true
      });
      this.currentIndex1 = allMarkers.length ? allMarkers.length + idx : idx

      marker['originalIcon'] = marker.getIcon();
      marker.addListener('click', () => this.onMarkerClick(item, marker));
      return marker;
    }, this);

    allMarkers = allMarkers.concat(batchMarkers); // Agrega el batch de markers al arreglo total de markers
    currentIndex += batchSize;

    if (currentIndex < markersData.length) {
      setTimeout(processBatch.bind(this)(), 0); // Pausa entre lotes para mantener la UI receptiva
    } else {
      console.log('All markers have been added to the map.');
    }
  }

  processBatch.bind(this)(); // Inicia el proceso por lotes
  this.markers = allMarkers;
  this.onMarkerClick(this.filteredArray[0], this.markers[0])
}



  private onBoundsChange(bounds: google.maps.LatLngBounds) {
    this.filteredArray = this.listings.filter(item => bounds.contains(new google.maps.LatLng(item.Latitude, item.Longitude)));
    this.updateMarkers();
  }

  private updateMarkers() {
    this.markers.forEach(marker => marker.setMap(null)); // Clear existing markers
    this.markers = []; // Reset marker array
      this.addMarkersInBatches(this.map, this.filteredArray);
  }


  async onMarkerClick(listing: any, marker: any) {

    // Si hay un marcador previamente enfocado, restablece su tamaño
    if (this.focusedMarker) {
      await this.resetMarkerIcon(this.focusedMarker);
    }

    // Asigna el marcador actual como enfocado y cambia su tamaño
    // const listing = this.markers[marker];
    this.focusMarker(marker);
    this.focusedMarker = marker;

    // Desplázate al índice correspondiente en el carrusel
    const listingIdx = this.filteredArray.findIndex(obj => obj.ListingID == listing.ListingID);
    this.slideWithNav2.slideTo(listingIdx);
  }

  private focusMarker(marker: google.maps.Marker) {
    if (!marker) return;
    const currentIcon = marker.getIcon() as google.maps.Icon;

    const newIcon = {
      ...currentIcon,
      size: new google.maps.Size(40, 50),
      scaledSize: new google.maps.Size(40, 50),
      anchor: new google.maps.Point(20, 50),
      zoom: 20,

    };
    marker.setZIndex(25)
    marker.setIcon(newIcon);
    marker.setOptions({ optimized: true });
    this.map.panTo(marker.getPosition());
  }


  async resetMarkerIcon(marker: google.maps.Marker) {
    const originalIcon = marker['originalIcon'] as google.maps.Icon;
    if (originalIcon) {
      const newIcon = {
        ...originalIcon,
        size: new google.maps.Size(22, 30),
        scaledSize: new google.maps.Size(22, 30),
        anchor: new google.maps.Point(20, 50),
      };
      marker.setIcon(originalIcon);
    }
  }


  // Distance-related methods
  trackLocation() {
    this.geolocation.getCurrentPosition().then((resp) => {
      this.userLocation = { latitude: resp.coords.latitude, longitude: resp.coords.longitude };
      this.latitude = this.userLocation.latitude;
      this.longitude = this.userLocation.longitude;
      this.setDistance();
    }).catch((error) => console.error('Error getting location', error));
  }

  private setDistance() {
    this.mapsAPILoader.load().then(() => {
      const location = new google.maps.LatLng(this.userLocation.latitude, this.userLocation.longitude);
      this.filteredArray.forEach(item => {
        const listingLocation = new google.maps.LatLng(item.Latitude, item.Longitude);
        const distanceInMeters = google.maps.geometry.spherical.computeDistanceBetween(location, listingLocation);
        item.Distance = this.metersToMiles(distanceInMeters);
      });
      this.filteredArray.sort((a, b) => a.Distance - b.Distance);
    });
  }

  private metersToMiles(meters: number): number {
    return +(meters * 0.000621371).toFixed(2);
  }

  //Method called when slide is changed by drag or navigation
  SlideDidChange(object, slideView) {
    this.filteredArray[this.previousIndex].IconWidth = 22;
    this.filteredArray[this.previousIndex].IconHeight = 30;
    this.filteredArray[this.previousIndex].zIndex = 1;

    this.slideWithNav2.getActiveIndex().then(index => {
      this.latitude = this.filteredArray[index].Latitude;
      this.longitude = this.filteredArray[index].Longitude;

      this.onMarkerClick(this.filteredArray[index], this.markers[index]);
      // this.filteredArray[index].Icon = 'assets/icons/pin_map.svg'
      this.filteredArray[index].IconWidth = 45;
      this.filteredArray[index].IconHeight = 60;
      this.filteredArray[index].zIndex = 1000;
      this.previousIndex = index

   });
  }

}
