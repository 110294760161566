import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedConstantsService {
  readonly apFullName: string = 'fullName';
  readonly apFirstName: string = 'firstName';
  readonly apLastName: string = 'lastName';
  readonly apCountry: string = 'country';
  readonly apState: string = 'state';
  readonly apZipCode: string = 'zip';

  constructor() { }
}
