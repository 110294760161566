import { settings as settings } from '../environments/settings';
export const environment = {
  ...settings,
  template: 'pwa-1.1',
  theme: {
    name: 'Pensacola',
    appName: 'Visit pensacola',
    productionHostName: 'visit-pensacola-bae00',
    stagingHostName: 'staging-pensacola',
    stagingdevHostName: 'dev-pensacola',
    appCode: 'VPEN'
},
  cms: {
    domainId: 12350,
    accountId: 1029,
    channelId: 1127,
    deviceId: 1059,
    formId: 158,
    postalCode: 32502,
    bandwangosPageId: 3388,
    visitorCenterId: 18004,
    main_coordinates: {
      latitude: 30.417575,
      longitude: -87.193379,
    },
    navigation: {
      avatar_enabled: false,
      homePageId: 4185,
      itemsPerRowCategory: 2
    }
  },
  regions:{
    enabled: false,
    regionsPageId: 0
  },
  coreAuthLib: {
    base_url: 'https://usersapi.trueomni.com',
    client_secret: 'Y95IIcP3Qku_e3xt_7HIHg',
  },
  firebase: {
    apiKey: "AIzaSyAA3ieCN58jrMWsC0z7nSonMSgEozsyQhM",
    authDomain: "visit-pensacola-bae00.firebaseapp.com",
    projectId: "visit-pensacola-bae00",
    storageBucket: "visit-pensacola-bae00.appspot.com",
    messagingSenderId: "346922895927",
    appId: "1:346922895927:web:4f15ed5c76b6a5f1a60db2",
    measurementId: "G-80CL0HTEYQ"
  },
  messagesSDK: {
    client_id: 1629,
    client_secret: 'otltRRcv6EKRhGo_XEDzTQ',
    base_url: 'https://pushapi.mobimanage.com'
  },
  socialWall: {
    client_id: '2248',
    client_secret: '2bGGWUbs9begrFJHZPBApqfSBEq7AGQ4spYUZ4m6=',
    url:''
  },
  googleMaps: 'AIzaSyAb0SnmmZsewjJYWVDY5U3D8-oo1zKh-Jg',
  google: {
    client_id: '346922895927-1ep0ajh4rstu23ijiuk3vrdf34bpldoi.apps.googleusercontent.com',
  },
  facebook: {
    app_id: '1435387714050929',
    version: 'v21.0'
  },
  batuta: {
    api_key: 'e8e98196453d6f0bd4ffd6553d56a829',
    base_url: 'http://battuta.medunes.net/api/'
  },
  tokenUniversal: {
    token: "DWuAh7qPqHqyp8la7Os4568OuHLu6dbpyvo11ZtsIfPvAnQgZOEPQk9Ssqdlb42VqVI"
  },
  ItineraryBuilder:{
    base_url: 'https://pensacola-ib-qa.trueomni.com/'
  },
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
